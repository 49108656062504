import { protectedAxios } from "./helpers";

export class DocumentService {

  static getDocuments(quota_id) {
    return protectedAxios.get(`/documents/`, {
      params: {
        quota_id: quota_id
      }
    });
  }

  static createDocument({ files, quota_id }) {
    let formData = new FormData()

    for (let file of files) {
      formData.append("files", file, file.name);
    }

    formData.append("quota_id", quota_id)
    return protectedAxios.post(`/documents/`, formData);
  }

  static getDocumentById(documentId, {typeFile, documentName}) {
    protectedAxios.get(`/documents/${documentId}`, {
      responseType: 'blob',
    }).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${documentName}.${typeFile}`)
      document.body.appendChild(link)
      link.click()
    })
      .catch(error => console.log(error));
  }

  static updateDocumentById(documentId, { fileName, file }) {
    let formData = new FormData()

    if (file) {
      formData.append("file", file, file.name);
    } else {
      formData.append("name", fileName)
    }

    return protectedAxios.put(`/documents/${documentId}`, formData);
  }

  static deleteDocumentById(documentId) {
    return protectedAxios.delete(`/documents/${documentId}`);
  }
}
